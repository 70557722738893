import { ApiInvestmentDataPoint } from '../../api/queries';

export type InvestmentDatapoint = ApiInvestmentDataPoint & {
  totalInvestment: number;
};

export type ConversionMeasurements = {
  modelledConversions: number;
  spend: number;
  impressions: number;
  clicks: number;
  cpa: number;
  frequency: number | null;
  weightedCPA: number | null;
};

export type CombinedConversionAndInvestmentDataPoint = {
  utcEpoch: number;
  campaignId: string;
  campaignTags: Array<{
    tagGroup: string;
    tag: string;
  }>;
  hasObservedData: boolean;
  hasModelledData: boolean;
  channel: string;
  deviceId?: string;
  messageId?: string;
  observedClicks?: number;
  spend?: number;
  observedImpressions?: number;
  modelledConversions?: number;
  observedCalculatedReach?: number;
};

export type ConversionsDataPoint = {
  utcEpoch: number;
  channel: string;
  campaignId: string;
  tag: string;
  measurements: ConversionMeasurements;
  byChannel: Map<string, ConversionMeasurements>;
  byCampaign: Map<string, ConversionMeasurements>;
  byTag: Map<string, ConversionMeasurements>;
};

export type ConversionsDataPointByTime = Pick<
  ConversionsDataPoint,
  'utcEpoch' | 'measurements'
>;

export type ConversionsDataPointByChannel = Pick<
  ConversionsDataPoint,
  'channel' | 'measurements'
>;
export type ConversionsDataPointByCampaign = Pick<
  ConversionsDataPoint,
  'campaignId' | 'measurements'
>;
export type ConversionsDataPointByTag = Pick<
  ConversionsDataPoint,
  'tag' | 'measurements'
>;

export type ConversionsDataPointByDimension =
  | ConversionsDataPointByChannel
  | ConversionsDataPointByTag
  | ConversionsDataPointByCampaign;

export const isByChannel = (
  dataPoint: ConversionsDataPointByDimension,
): dataPoint is ConversionsDataPointByChannel => {
  return (dataPoint as ConversionsDataPointByChannel).channel !== undefined;
};
export const isByTag = (
  dataPoint: ConversionsDataPointByDimension,
): dataPoint is ConversionsDataPointByTag => {
  return (dataPoint as ConversionsDataPointByTag).tag !== undefined;
};

export const isByCampaign = (
  dataPoint: ConversionsDataPointByDimension,
): dataPoint is ConversionsDataPointByCampaign => {
  return (dataPoint as ConversionsDataPointByCampaign).campaignId !== undefined;
};
