import { useMemo } from 'react';
import {
  floatCellFunc,
  identityCellFunc,
  intCellFunc,
  intEuroCellFunc,
} from 'src/components/molecules/DataTable/cellFuncs';

import { createColumnHelper } from '@tanstack/react-table';
import { useKpiUnit } from 'src/hooks/useKpiUnit';
import {
  BENCHMARK_CLICKS_NAME,
  BENCHMARK_CPA_NAME,
  BENCHMARK_FREQUENCY_NAME,
  BENCHMARK_IMPRESSIONS_NAME,
  BENCHMARK_MODELLED_CONV_NAME,
  BENCHMARK_SPEND_NAME,
  BENCHMARK_WEIGHTED_CPA_NAME,
  MODELLED_CONV_NAME,
  WEIGHTED_CPA_NAME,
} from '../utils/constants';
import { useBenchmarkEnabled } from './useBenchmarkEnabled';
import { MetricsData, MetricsKey } from './useMetrics';

const columnHelper = createColumnHelper<Partial<MetricsData>>();

export const useMetricTableColumns = (dimensionName: MetricsKey) => {
  const showWeightedCPA = dimensionName === 'Channel';

  const { unit } = useKpiUnit();
  const { conversionsBenchmarkEnabled } = useBenchmarkEnabled();

  const columns = useMemo(() => {
    const effectCellFunc = unit === 'euro' ? intEuroCellFunc : intCellFunc;

    return [
      columnHelper.accessor('key', {
        header: dimensionName,
        cell: identityCellFunc,
        sortingFn: 'alphanumeric',
      }),

      columnHelper.accessor(MODELLED_CONV_NAME, {
        header: MODELLED_CONV_NAME,
        cell: effectCellFunc,
        sortingFn: 'basic',
      }),

      columnHelper.accessor(BENCHMARK_MODELLED_CONV_NAME, {
        header: BENCHMARK_MODELLED_CONV_NAME,
        cell: effectCellFunc,
        sortingFn: 'basic',
        meta: {
          isBenchmark: true,
        },
      }),

      columnHelper.accessor('Spend', {
        header: 'Spend',
        cell: intEuroCellFunc,
        sortingFn: 'basic',
      }),

      columnHelper.accessor(BENCHMARK_SPEND_NAME, {
        header: BENCHMARK_SPEND_NAME,
        cell: intEuroCellFunc,
        sortingFn: 'basic',
        meta: {
          isBenchmark: true,
        },
      }),

      columnHelper.accessor('CPA', {
        header: 'CPA',
        cell: floatCellFunc,
        sortingFn: 'basic',
      }),

      columnHelper.accessor(BENCHMARK_CPA_NAME, {
        header: BENCHMARK_CPA_NAME,
        cell: floatCellFunc,
        sortingFn: 'basic',
        meta: {
          isBenchmark: true,
        },
      }),

      columnHelper.accessor(WEIGHTED_CPA_NAME, {
        header: WEIGHTED_CPA_NAME,
        cell: floatCellFunc,
        sortingFn: 'basic',
      }),

      columnHelper.accessor(BENCHMARK_WEIGHTED_CPA_NAME, {
        header: BENCHMARK_WEIGHTED_CPA_NAME,
        cell: floatCellFunc,
        sortingFn: 'basic',
        meta: {
          isBenchmark: true,
        },
      }),

      columnHelper.accessor('Impressions', {
        header: 'Impressions',
        cell: intCellFunc,
        sortingFn: 'basic',
      }),

      columnHelper.accessor(BENCHMARK_IMPRESSIONS_NAME, {
        header: BENCHMARK_IMPRESSIONS_NAME,
        cell: intCellFunc,
        sortingFn: 'basic',
        meta: {
          isBenchmark: true,
        },
      }),

      columnHelper.accessor('Clicks', {
        header: 'Clicks',
        cell: intCellFunc,
        sortingFn: 'basic',
      }),

      columnHelper.accessor(BENCHMARK_CLICKS_NAME, {
        header: BENCHMARK_CLICKS_NAME,
        cell: intCellFunc,
        sortingFn: 'basic',
        meta: {
          isBenchmark: true,
        },
      }),

      columnHelper.accessor('Frequency', {
        header: 'Frequency',
        cell: floatCellFunc,
        sortingFn: 'basic',
      }),

      columnHelper.accessor(BENCHMARK_FREQUENCY_NAME, {
        header: BENCHMARK_FREQUENCY_NAME,
        cell: floatCellFunc,
        sortingFn: 'basic',
        meta: {
          isBenchmark: true,
        },
      }),
    ].filter((d) => {
      // Remove benchmark columns if benchmark not enabled
      if (d.meta?.isBenchmark && !conversionsBenchmarkEnabled) {
        return false;
      }

      const isWeightedCpaRelatedColumn =
        d.accessorKey === WEIGHTED_CPA_NAME ||
        d.accessorKey === BENCHMARK_WEIGHTED_CPA_NAME;

      if (!showWeightedCPA && isWeightedCpaRelatedColumn) {
        return false;
      }

      // Keyword cluster only supports specific columns
      if (dimensionName === 'Keyword cluster') {
        const header = d.header;

        const acceptedColumns = [
          dimensionName,
          'Impressions',
          BENCHMARK_IMPRESSIONS_NAME,
          MODELLED_CONV_NAME,
          BENCHMARK_MODELLED_CONV_NAME,
        ];

        const headerIsAccepted = acceptedColumns.includes(
          header as keyof MetricsData,
        );

        return headerIsAccepted;
      }

      return true;
    });
  }, [unit, dimensionName, conversionsBenchmarkEnabled, showWeightedCPA]);

  return columns;
};
