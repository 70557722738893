import {
  ApiDecompDataPoint,
  ApiInvestmentDataPoint,
  ApiObservedConversionDataPoint,
} from 'src/api/queries';
import { AggregateLevel, Campaign } from 'src/types';
import { getAggregateStartEpochFunc } from 'src/utils/date';
import { ConversionsDataState } from '../hooks/useConversions';
import {
  CombinedConversionAndInvestmentDataPoint,
  ConversionMeasurements,
  ConversionsDataPointByCampaign,
  ConversionsDataPointByChannel,
  ConversionsDataPointByTag,
  ConversionsDataPointByTime,
} from '../types';

export const calculateFrequency = (
  impressions: number,
  calculatedReach: number,
): number => {
  if (calculatedReach === 0) {
    return 0;
  }

  return Number((impressions / calculatedReach).toFixed(2));
};

// Here we avoid showing really large total CPA
export const calculateTotalCPA = (
  spend: number,
  modelledConversions: number,
) => {
  if (modelledConversions < 1) {
    return 0;
  }
  return spend / modelledConversions;
};

export const calculateWeightedCPA = (
  totalSpend: number,
  datapoints: CombinedConversionAndInvestmentDataPoint[],
): number => {
  if (totalSpend === 0) {
    return 0;
  }

  let weightedCpa = 0;

  for (const { spend, modelledConversions } of datapoints) {
    // its ok we skip 0 values as they won't contribute to the weighted CPA anyways
    if (!spend || !modelledConversions) {
      continue;
    }

    const cpa = spend / modelledConversions;

    weightedCpa += (cpa * spend) / totalSpend;
  }

  return weightedCpa;
};

// Used to ensure we extract only fields we want
const getDimensions = (identifier: {
  campaignId: string;
  channel: string;
  deviceId?: string;
  messageId?: string;
}) => {
  return {
    campaignId: identifier.campaignId,
    channel: identifier.channel,
    deviceId: identifier.deviceId,
    messageId: identifier.messageId,
  };
};

const getCombineKey = (
  utcEpoch: number,
  identifier: {
    campaignId: string;
    channel: string;
    deviceId?: string;
    messageId?: string;
  },
): string => {
  const { campaignId, channel, deviceId, messageId } = identifier;
  return `${utcEpoch}_${campaignId}_${channel}_${deviceId}_${messageId}`;
};

const combineConversionAndInvestment = (
  allObservedConversions: Array<ApiObservedConversionDataPoint>,
  allModelledConversions: Array<ApiDecompDataPoint>,
  allInvestments: Array<ApiInvestmentDataPoint>,
  campaignsMap: Record<string, Campaign>,
): Array<CombinedConversionAndInvestmentDataPoint> => {
  const combineMap: Record<string, CombinedConversionAndInvestmentDataPoint> =
    {};
  allObservedConversions.forEach(({ utcEpoch, conversions }) => {
    conversions.forEach((conversion) => {
      const campaign = campaignsMap[conversion.campaignId];
      if (!campaign) {
        return;
      }
      const campaignTags = campaign.tags;
      const key = getCombineKey(utcEpoch, conversion);
      combineMap[key] = {
        utcEpoch,
        campaignTags,
        ...getDimensions(conversion),
        hasObservedData: true,
        hasModelledData: false,
        observedCalculatedReach: conversion.calculatedReach,
        observedClicks: conversion.clicks,
        observedImpressions: conversion.impressions,
      };
    });
  });

  allModelledConversions.forEach(({ utcEpoch, createdValue }) => {
    createdValue.forEach((conversion) => {
      const key = getCombineKey(utcEpoch, conversion);
      if (combineMap[key]) {
        combineMap[key] = {
          ...combineMap[key],
          hasModelledData: true,
          modelledConversions: conversion.value,
        };
      } else {
        const campaign = campaignsMap[conversion.campaignId];
        if (!campaign) {
          return;
        }

        const campaignTags = campaign.tags;
        combineMap[key] = {
          utcEpoch,
          campaignTags,
          hasModelledData: true, // If key does not exist at this point, then observed data must be false as its handled above
          hasObservedData: false,
          ...getDimensions(conversion),
          modelledConversions: conversion.value,
        };
      }
    });
  });

  allInvestments.forEach(({ utcEpoch, investments }) => {
    investments.forEach((investment) => {
      const key = getCombineKey(utcEpoch, investment);
      if (combineMap[key]) {
        combineMap[key] = { ...combineMap[key], spend: investment.value };
      } else {
        const campaign = campaignsMap[investment.campaignId];
        if (!campaign) {
          return;
        }
        const campaignTags = campaign.tags;
        combineMap[key] = {
          utcEpoch,
          campaignTags,
          ...getDimensions(investment),
          hasObservedData: false, // If key does not exist at this point, then both observed and modelled data are false
          hasModelledData: false,
          spend: investment.value,
        };
      }
    });
  });
  return Object.values(combineMap);
};

type GetMeasurementsParams = {
  groupedMeasurements: CombinedConversionAndInvestmentDataPoint[];
  includeWeightedCPA: boolean;
};

const getMeasurements = ({
  groupedMeasurements,
  includeWeightedCPA,
}: GetMeasurementsParams) => {
  const m: ConversionMeasurements = {
    modelledConversions: 0,
    spend: 0,
    impressions: 0,
    clicks: 0,
    cpa: 0,
    frequency: null,
    weightedCPA: null,
  };

  let calculatedReach = 0;

  let hasFreqSupport = true;

  for (const data of groupedMeasurements) {
    if (data.observedCalculatedReach === undefined && data.hasObservedData) {
      hasFreqSupport = false;
    }

    m.modelledConversions += data?.modelledConversions ?? 0;
    m.impressions += data?.observedImpressions ?? 0;
    m.clicks += data?.observedClicks ?? 0;
    m.spend += data?.spend ?? 0;
    calculatedReach += data.observedCalculatedReach ?? 0;
  }

  if (hasFreqSupport) {
    m.frequency = calculateFrequency(m.impressions, calculatedReach);
  }

  if (includeWeightedCPA) {
    m.weightedCPA = calculateWeightedCPA(m.spend, groupedMeasurements);
  }

  const cpa = calculateTotalCPA(m.spend, m.modelledConversions);
  m.cpa = cpa;

  return m;
};

const aggregateByEpoch = (
  combinedData: CombinedConversionAndInvestmentDataPoint[],
  aggregateLevel: AggregateLevel,
) => {
  const getDateStartEpoch = getAggregateStartEpochFunc(aggregateLevel);

  const dataByEpoch: Map<number, CombinedConversionAndInvestmentDataPoint[]> =
    new Map();

  combinedData.forEach((data) => {
    const epoch = getDateStartEpoch(data.utcEpoch);

    const existingData = dataByEpoch.get(epoch) ?? [];

    dataByEpoch.set(epoch, existingData.concat(data));
  });

  const timeframeData: ConversionsDataPointByTime[] = [];

  dataByEpoch.forEach((measurements, utcEpoch: number) => {
    const datapoint: ConversionsDataPointByTime = {
      utcEpoch,
      measurements: getMeasurements({
        groupedMeasurements: measurements,
        includeWeightedCPA: false,
      }),
    };
    timeframeData.push(datapoint);
  });

  return timeframeData;
};

const aggregateConversionsDataByChannel = (
  combinedData: Array<CombinedConversionAndInvestmentDataPoint>,
): ConversionsDataPointByChannel[] => {
  // Gather data for month.
  const byChannel: Map<string, CombinedConversionAndInvestmentDataPoint[]> =
    new Map();

  combinedData.forEach((data) => {
    const channel = data.channel;

    const existingData = byChannel.get(channel) ?? [];

    byChannel.set(channel, existingData.concat(data));
  });

  const dataPoints: ConversionsDataPointByChannel[] = [];

  byChannel.forEach((measurements, channel) => {
    dataPoints.push({
      channel,
      measurements: getMeasurements({
        groupedMeasurements: measurements,
        includeWeightedCPA: true, // For conversion by channel we want to calculate the weighted cpa
      }),
    });
  });

  return dataPoints;
};

const aggregateConversionsDataByCampaign = (
  combinedData: Array<CombinedConversionAndInvestmentDataPoint>,
): ConversionsDataPointByCampaign[] => {
  const byCampaignId: Map<string, CombinedConversionAndInvestmentDataPoint[]> =
    new Map();

  combinedData.forEach((data) => {
    const campaignId = data.campaignId;

    const existingData = byCampaignId.get(campaignId) ?? [];

    byCampaignId.set(campaignId, existingData.concat(data));
  });

  const dataPoints: ConversionsDataPointByCampaign[] = [];
  byCampaignId.forEach((measurements, campaignId) => {
    dataPoints.push({
      campaignId,
      measurements: getMeasurements({
        groupedMeasurements: measurements,
        includeWeightedCPA: false,
      }),
    });
  });

  return dataPoints;
};

const aggregateConversionsDataByTag = (
  combinedData: Array<CombinedConversionAndInvestmentDataPoint>,
): ConversionsDataPointByTag[] => {
  const byTag: Map<string, CombinedConversionAndInvestmentDataPoint[]> =
    new Map();

  combinedData.forEach((data) => {
    data.campaignTags.forEach(({ tag }) => {
      const existingData = byTag.get(tag) ?? [];

      byTag.set(tag, existingData.concat(data));
    });
  });
  const dataPoints: ConversionsDataPointByTag[] = [];
  byTag.forEach((measurements, tag) => {
    dataPoints.push({
      tag,
      measurements: getMeasurements({
        groupedMeasurements: measurements,
        includeWeightedCPA: false,
      }),
    });
  });

  return dataPoints;
};

export const aggregateConversions = (
  allObservedConversions: Array<ApiObservedConversionDataPoint>,
  allModelledConversions: Array<ApiDecompDataPoint>,
  allInvestments: Array<ApiInvestmentDataPoint>,
  campaignsMap: Record<string, Campaign>,
  isLoading: boolean,
  isError: boolean,
): ConversionsDataState => {
  const combinedData = combineConversionAndInvestment(
    allObservedConversions,
    allModelledConversions,
    allInvestments,
    campaignsMap,
  );

  const data: ConversionsDataPointByTime[] = aggregateByEpoch(
    combinedData,
    AggregateLevel.day,
  );

  const weekly: ConversionsDataPointByTime[] = aggregateByEpoch(
    combinedData,
    AggregateLevel.week,
  );

  const monthly: ConversionsDataPointByTime[] = aggregateByEpoch(
    combinedData,
    AggregateLevel.month,
  );

  const byChannel = aggregateConversionsDataByChannel(combinedData);

  const byCampaign: ConversionsDataPointByCampaign[] =
    aggregateConversionsDataByCampaign(combinedData);

  const byTag: ConversionsDataPointByTag[] =
    aggregateConversionsDataByTag(combinedData);

  const dataState: ConversionsDataState = {
    data,
    weekly,
    monthly,
    byCampaign,
    byChannel,
    byTag,
    isError,
    isLoading,
  };

  return dataState;
};
