import React, { useMemo } from 'react';
import {
  KeyMetricChart,
  KeyMetricChartData,
} from 'src/components/organisms/KeyMetricChart';
import { useBenchmarkEnabled } from 'src/features/DA/hooks/useBenchmarkEnabled';
import { useMergeBenchmarkTimeseries } from 'src/hooks/useMergeBenchmarkTimeseries';
import { useDADataContext } from '../../../contexts/DADataContext';
import { calculateTotalCPA } from '../../../utils/conversionsAggregator';

export const CPA: React.FC = () => {
  const { conversions, benchmarkConversions } = useDADataContext();
  const { conversionsBenchmarkEnabled } = useBenchmarkEnabled();
  const { getMergedTimeSeries } = useMergeBenchmarkTimeseries();

  const keyMetricData: KeyMetricChartData = useMemo(() => {
    let spendSum = 0;
    let conversionsSum = 0;

    const data = getMergedTimeSeries(
      conversions.data,
      benchmarkConversions.data,
      (v) => ({ value: v.measurements.cpa }),
      (v) => ({ benchmark: v.measurements.cpa }),
      conversionsBenchmarkEnabled,
      'day',
    );

    conversions.data.forEach((x) => {
      spendSum += x.measurements.spend;
      conversionsSum += x.measurements.modelledConversions;
    });

    let benchmarkSpendSum = 0;
    let benchmarkConversionsSum = 0;

    if (conversionsBenchmarkEnabled) {
      benchmarkConversions.data.forEach((x) => {
        benchmarkSpendSum += x.measurements.spend;
        benchmarkConversionsSum += x.measurements.modelledConversions;
      });
    }

    return {
      name: 'CPA',
      data,
      isEuro: false,
      summaryValue: calculateTotalCPA(spendSum, conversionsSum),
      benchmarkSummaryValue: conversionsBenchmarkEnabled
        ? calculateTotalCPA(benchmarkSpendSum, benchmarkConversionsSum)
        : undefined,
      isLoading: conversions.isLoading,
      isError: conversions.isError,
    };
  }, [
    conversions,
    getMergedTimeSeries,
    conversionsBenchmarkEnabled,
    benchmarkConversions.data,
  ]);

  return <KeyMetricChart keyMetricData={keyMetricData} />;
};
