export const MODELLED_CONV_NAME = 'Modelled Media-Driven Conversions';
export const WEIGHTED_CPA_NAME = 'Weighted CPA';

export const BENCHMARK_MODELLED_CONV_NAME = 'Conversions (Bench)';
export const BENCHMARK_SPEND_NAME = 'Spend (Bench)';
export const BENCHMARK_IMPRESSIONS_NAME = 'Impressions (Bench)';
export const BENCHMARK_CPA_NAME = 'CPA (Bench)';
export const BENCHMARK_CLICKS_NAME = 'Clicks (Bench)';
export const BENCHMARK_FREQUENCY_NAME = 'Frequency (Bench)';
export const BENCHMARK_WEIGHTED_CPA_NAME = 'Weighted CPA (Bench)';
